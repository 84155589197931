import React from 'react'
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography'

class About extends React.Component {
  state = require('../../package.json')

  render() {
    return (
      <div>
        <Typography variant="h3" > About < /Typography>

        { this.props.idObject &&
          <div>
            <Typography variant="h4" > Identity < /Typography>
            <Typography varaint = "paragraph">First Name: {this.props.idObject.given_name}</Typography>
            <Typography varaint = "paragraph">Last Name: {this.props.idObject.family_name}</Typography>
            <Typography varaint = "paragraph">Email: {this.props.idObject.email}</Typography>
          </div>
        }
        <Typography variant="h4" > The App < /Typography>
        <Typography variant="body1">Name: {this.state.name}</Typography>
        <Typography variant="body1">Version: {this.state.version}</Typography>
        <Typography variant = "h4"> Dependencies </Typography>
        { Object.keys(this.state.dependencies).map((key)=>{
          return <Typography key={key}>{key} {this.state.dependencies[key]} </Typography>
        })
        }


      </div>
    )
  }
  // <Typography variant="body1">{JSON.stringify(tmp)}</Typography>
}

//
// <Typography variant = "h4" > GroupMap < /Typography>
// <Typography variant="body1">{JSON.stringify(this.props.groupMap)}</Typography>

About.propTypes = {
  //classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired,
};

export default About
