import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import ValueSet from './ValueSet'

class Group extends React.Component {

  state = {
    valueSetMap: {},
    memberMap: {}
  }

  fetchGroup = (groupID) => {
    //Move to rate component...
    //this.props.api.get('directory/groups/'+groupID).then(
    this.props.api.get('directory/group/'+groupID+'/users').then(
      (r)=>{
        this.setState({memberMap: r.data})
      },
      (error)=>{console.error(error)}
    )
    //keep
    //this.props.api.get('valueSets/'+groupID).then(
    this.props.api.get(`groups/${groupID}/value-sets`).then(
      (r)=>{this.setState({valueSetMap: r.data})},
      (error)=>{console.error(error)}
    )
  }

  componentWillMount = () => {
    this.fetchGroup(this.props.groupID)
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.groupID !== this.props.groupID)
      this.fetchGroup(nextProps.groupID)
  }

  handleChange = (event, value) => {
    this.setState({
      value
    })
  }

  render() {
    //console.log(this.state.valueSetMap)
    return (
      <div>
        {/*<Typography variant="h3" >{this.props.groupID} ({Object.keys(this.state.memberMap).length})< /Typography>*/}

        {Object.keys(this.state.valueSetMap).sort((a,b)=>{
            if(this.state.valueSetMap[a].endDate > this.state.valueSetMap[b].endDate) return -1
            if(this.state.valueSetMap[a].endDate < this.state.valueSetMap[b].endDate) return -1
            return 0
        }).map((key)=>{
          return <>
            <ValueSet key={'vs-'+key} api={this.props.api} idObject={this.props.idObject} groupID={this.props.groupID} memberMap={this.state.memberMap} valueSetID={key} valueSet={this.state.valueSetMap[key]} />
            <br/>
            </>
        })}


      </div>
    )

  }
}

Group.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}
export default Group
