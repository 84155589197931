import jwtDecode from 'jwt-decode'
import packageInfo from '../package.json'
class API {
  constructor(jwt) {
    this.jwt = jwt
    this.decodedJwt = jwtDecode(this.jwt)
    //this.base = 'http://[::1]:3030/fake.com'
    //this.base = ''
    //this.base = 'https://7w6gfy8ok9.execute-api.us-east-1.amazonaws.com/dev'
    this.base = process.env.REACT_APP_API_BASE_URL
  }
  get = (path) => {
    return (
      new Promise((resolve, reject) => {
        if(this.decodedJwt.exp < (Date.now()/1000)){
          this.jwt = undefined
          //window.location.replace('/app')
          window.location.replace(packageInfo.homepage)
          reject(new Error('Auth timeout'))
        } else {
          //fetch(this.base + '/api/' + path, {
          fetch(this.base + '/' + path, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': 'Bearer ' + this.jwt
              } //,
              // body: JSON.stringify({ username: username, password: password })
            })
            .then((response) => {
              if (response.status >= 400) {
                // throw new Error('Bad response from server')
                reject(new Error(response.statusCode))
              }
              let retval
              try{
                retval = response.json()
              }catch(e){
                retval = new Error(response.statusCode)
              }
              return retval //response.json()
            }, (error) => {
              console.error("TBIRT", error)
              reject(new Error(error))
            })
            .then((data) => {
              resolve(data)
            })
        }
      })

    )
  }

  post = (path, json) => {
    return (
      new Promise((resolve, reject) => {
        if(this.decodedJwt.exp < (Date.now()/1000)){
          this.jwt = undefined
          reject(new Error('Auth timeout'))
        } else {
          fetch(this.base + '/' + path, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': 'Bearer ' + this.jwt
              },
              body: JSON.stringify(json)
            })
            .then((response) => {
              if (response.status >= 400) {
                // throw new Error('Bad response from server')
                reject(new Error(response.statusCode))
              }
              let retval
              try{
                retval = response.json()
              }catch(e){
                retval = new Error(response.statusCode)
              }
              return retval //response.json()
            }, (error) => {
              reject(new Error(error))
            })
            .then((data) => {
              resolve(data)
            })
        }
      }))
  }
}

export default API
