import React from 'react'
import PropTypes from 'prop-types'
import C3Chart from 'react-c3js'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button'
import 'c3/c3.css'
import './my.css'

class CORComponent extends React.Component {
  state = {
    valueStatMap: {},
    context: '',
    observation: '',
    result: '',
    corMap: {}
  }
  componentWillMount = () => {
    //let url = 'stats/valueSetScores/'+this.props.valueSetID
    let url = `groups/${this.props.groupID}/value-sets/${this.props.valueSetID}/values`
    // if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
    //   url+='/'+this.props.idObject.sub
    // }
    this.props.api.get(url).then(
      (result)=>{
        // console.log("STAT DATA", result.data)
        this.setState({valueMap: result.data})
      }
    ).then(()=>{

    let url2 = `cor`
    this.props.api.get(url2).then(
      (result)=>{
        if(this.state.valueMap){
          // console.log('valueMap', this.state.valueMap)
          let validIds = this.state.valueMap.map((value)=>{return value.id})
          // console.log('ValidIDs', validIds)
          let toAdd = result.data.filter((item)=>{
            return validIds.indexOf(item.ValueId) !== -1
          })
          // console.log("ToAdd", toAdd)
          this.setState({corMap: toAdd.reduce((acc, item)=>{
            acc[item.id] = item
            return acc
          }, {})})
        } else {
          // console.log('no state.valueMap')
        }
      },
      (error)=>{
        console.error(error)
      }
    )
  })


  }
  pickSubject = (event, value, reason) => {
    this.setState({selectedMemberKey: value ? value.key : undefined})
  }
  pickValue = (event, value, reason) => {
    this.setState({selectedValueKey: value ? value.key : undefined})
  }
  setContext = (e) => {
    // console.log(e)
    this.setState({context: e.target.value})
  }
  setObservation = (e) => {
    // console.log(e)
    this.setState({observation: e.target.value})
  }
  setResult = (e) => {
    // console.log(e)
    this.setState({result: e.target.value})
  }

  postCOR = () => {
    const cor = {
      SubjectId: this.state.selectedMemberKey,
      ValueId: this.state.valueMap[this.state.selectedValueKey].id,
      context: this.state.context,
      observation: this.state.observation,
      result: this.state.result
    }
    this.props.api.post('cor', cor).then(
      (result)=>{
        console.log('gone!')
      },
      (error)=>{
        console.error(error)
      }
    )
  }
  render () {
    // const personStat = this.props.valueStat.stats[this.props.idObject.sub]
    // const groupStat = this.props.valueStat.stats['*']
    // console.log(this.state.corMap)
    return (
      <>

          {/*JSON.stringify(this.props.memberMap)*/}
          {/*JSON.stringify(this.state.valueMap)*/}
          <h3>For Others</h3>
          {
            <Autocomplete
                id="groupMemberAutoComplete"
                onChange={this.pickSubject}

                options={
                  this.props.memberMap ?
                    Object.keys(this.props.memberMap).map(
                      (key)=>{
                        return {
                          key:key,
                          firstName: this.props.memberMap[key].firstName,
                          lastName: this.props.memberMap[key].lastName}}).sort((a,b)=>{if(a.lastName+a.firstName < b.lastName+b.firstName) return -1; if(a.lastname+a.firstName > b.lastName+b.firstName) return 1; return 0}) : []}
                getOptionLabel={option => option.firstName + ' ' + option.lastName}
                style={{ width: 300 }}
                renderInput={params => <TextField {...params} label="Choose a person" variant="outlined" />}
              />
          }

          {
            <Autocomplete
                id="valueAutoComplete"
                onChange={this.pickValue}

                options={
                  this.state.valueMap ?
                    Object.keys(this.state.valueMap).map(
                      (key)=>{
                        return {
                          key:key,
                          name: this.state.valueMap[key].name,
                          description: this.state.valueMap[key].description,
                          ordinal: this.state.valueMap[key].ordinal
                        }
                      }).sort((a,b)=>{
                        if(a.ordinal < b.ordinal) return -1;
                        if(a.ordinal > b.ordinal) return 1;
                        return 0}) : []}
                getOptionLabel={option => option.name}
                style={{ width: 300 }}
                renderInput={params => <TextField {...params} label="Choose a value" variant="outlined" />}
              />
          }
          {(this.state.selectedValueKey && this.state.selectedMemberKey) ?
            <>
              With respect to {this.state.valueMap[this.state.selectedValueKey].name} (
                {this.state.valueMap[this.state.selectedValueKey].description}
              ), describe the context, your observation, and the result you saw.<br/>

            <TextField
              id="context"
              label="Context"
              multiline
              rows={4}
              onChange={this.setContext}
              placeholder="Describe the context"
              variant="outlined"
            />
            <TextField
              id="observation"
              label="Observation"
              multiline
              rows={4}
              onChange={this.setObservation}
              placeholder="Describe your observation"
              variant="outlined"
            />
            <TextField
              id="result"
              label="Result"
              multiline
              rows={4}
              onChange={this.setResult}
              placeholder="Describe the result you perceived"
              variant="outlined"
            />

            <Button variant="contained" onClick={this.postCOR}>Send</Button>

            </> : ''
          }

          <h3>For You</h3>
          {
            this.state.corMap && Object.values(this.state.corMap).map((cor)=>{
                return <p>{cor.createdAt} {this.state.valueMap.filter((item)=>{return item.id === cor.ValueId})[0].name} {cor.context} {cor.observation} {cor.result}</p>
            })
          }
      </>
    )
  }
}

CORComponent.propTypes = {
  // classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired
}
export default CORComponent
