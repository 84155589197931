import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import Support from './Support'
import About from './About'
import Home from './Home'
import Login from './Login'
import Group from './Group'

import {
  withStyles
} from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Link,
  Switch
} from 'react-router-dom'

import Authenticator from '../auth'
import API from '../api'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    //margin: theme.spacing.unit * 2,
  },
});



function Help({
  match
}) {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
      Topics
      </Typography>
      <ul>
        <li>
          <Link to={`${match.url}/groups`}>Groups</Link>
        </li>
        <li>
          <Link to={`${match.url}/valueSets`}>Value Sets</Link>
        </li>
        <li>
          <Link to={`${match.url}/values`}>Values</Link>
        </li>
        <li>
          <Link to={`${match.url}/measures`}>Measures</Link>
        </li>
        <li>
          <Link to={`${match.url}/observations`}>Observations</Link>
        </li>
        <li>
          <Link to={`${match.url}/cor`}>COR</Link>
        </li>
      </ul>

      <Route path={`${match.path}/groups`} component={GroupHelp} />
      <Route path={`${match.path}/valueSets`} component={ValueSetHelp} />
      <Route path={`${match.path}/values`} component={ValuesHelp} />
      <Route path={`${match.path}/measures`} component={MeasuresHelp} />
      <Route path={`${match.path}/observations`} component={ObservationsHelp} />
      <Route path={`${match.path}/cor`} component={CORHelp} />

      <Route
        exact
        path={match.path}
        render={() => <h3>Please select a topic.</h3>}
      />
    </div>
  );
}

function GroupHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">Groups</Typography>
      <p>
      P33Rs is tied to your organization's directory services and uses the
      groups defined there to organize its data. In the demo datasets there are
      "Employee" and "Software" groups. Each group in P33Rs can have any number
      of ValueSets.

      All P33Rs users can browse and provide feedback to all groups.

      When you expose a custom "adminOf" assertion in your identity provider,
      P33Rs will allow users with that assertion to manage P33Rs data for that
      group (e.g. creating or modifying ValueSets).
      </p>
    </div>
  );
}
function ValueSetHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">ValueSets</Typography>
      <p>
      ValueSets are collections of Values. They can have effective dates allowing
      you to run "campaigns" within your organization highlighting strategic initiatives
      or goals.
      </p>
    </div>
  );
}
function ValuesHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">Values</Typography>
      <p>
      Values are aspirations or deisred characteristics.
      </p>
    </div>
  );
}
function MeasuresHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">Measures</Typography>
      <p>
      Measures are used to track progress toward acheiving values. Values can
      have any number of linked measures. In the demo datasets each Value has
      two measures, one expressed positively, and one expressed negatively, a
      common technique in survey methodology to reach better measurement on
      subjective issues. Positive and negative measures are combined to provide
      a single score for the Value they're associated with.
      </p>
    </div>
  );
}
function ObservationsHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">Observations</Typography>
      <p>
      Observations are one of the things that P33Rs users record to provide
      feedback to each other. Observations are directly tied to measures, which
      forces them to be aligned with Values and ValueSets your organization defines.
      </p>
    </div>
  );
}
function CORHelp({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">COR</Typography>
      <p>
      Context, Observation, Result is a formulaic approach to providing timely
      and concise feedback to P33Rs. COR support in P33Rs is linked to Values
      to keep the feedback aligned with your efforts.
      </p>
    </div>
  );
}


function Topic({
  match
}) {
  //console.log('MATCH=', match)
  return (
    <div>
      <Typography variant="h5">{match.params.topicId}</Typography>
    </div>
  );
}

class ResponsiveDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      groupMap: {},
      crumbText: 'P33Rs'
    }
    this.authenticateCallback = this.authenticateCallback.bind(this)
    this.cognitoAuthenticateCallback = this.cognitoAuthenticateCallback.bind(this)
    this.authenticator = new Authenticator(this.authenticateCallback, this.cognitoAuthenticateCallback)
  }
  // fetchTest = async (api) => {
  //   return (await api.get2('https://7w6gfy8ok9.execute-api.us-east-1.amazonaws.com/dev/valueSets')).data
  // }
  fetchGroups = async (api) => {
    //return (await api.get('directory/groups')).data
    return (await api.get('directory/groups')).data
  }
  async cognitoAuthenticateCallback(r) {
    console.log("DO APP LOGIN WITH", r)
  }
  async authenticateCallback(idToken, accessToken, idObject, accessObject) {
    // console.log("idToken", idToken, "accessToken", accessToken, "idObject", idObject, "accessObject", accessObject)
    if(!idToken || !accessToken || !idObject || !accessObject){
      //window.location = '/app2'
      window.location = '/'
    } else {
      const api = new API(idToken/*accessToken*/)
      this.setState({
        api: api,
        idToken: idToken,
        accessToken: accessToken,
        idObject: idObject,
        accessObject: accessObject,
      })

      let promises = [
        //this.fetchTest(api),
        this.fetchGroups(api)
      ]

      Promise.all(promises).then(
        (results) => {
          this.setState({
            groupMap: results[0]
          })
        },
        (error) => {
          console.error("ERROR", error)
        }
      )
    }
  }

  handleDrawerToggle = (text) => {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen,
      crumbText: typeof text === 'string' ? text : ''
    }))
  }

  setCrumbText = (text) => {
    this.setState({crumbText: text})
  }
  // handleNotImplementedClick = () => {
  //   this.setState(state => ({
  //     open: true
  //   }))
  // }
  //
  // handleClose = () => {
  //   this.setState(state => ({
  //     open: false
  //   }))
  // }

  handleLog = () => {
    if (!this.state.idToken/*accessToken*/) {} else {
      this.setState({
        idToken: undefined,
        idObject: undefined,
        accessToken: undefined,
        accessObject: undefined
      })
      this.handleDrawerToggle()
    }
  }

  handleGroupClick = (groupID, event) => {
    this.handleDrawerToggle()
    this.setState({
      selectedGroupID: groupID,
      crumbText: groupID
    })
  }
  titleCase = (str) => {
    if(!str) return str
    return str.split().map((ss)=>{
      return ss.substring(0,1).toUpperCase() + ss.substring(1)
    }).join(' ')
  }
  generateListItems = () => {
    // console.log('generateListItems', this.state)
    // console.log(this.state.idObject)
    return (
      Object.keys(this.state.groupMap).map((key)=>{
        // console.log("CHECK", key, "in", this.state.idObject['cognito:groups'], this.state.idObject['cognito:groups'].indexOf(key))
        return (
          <Link key={key} to="/group" onClick={this.handleGroupClick.bind(this, key)}>
            <ListItem button key={key}>
              <ListItemIcon>
                  <PeopleIcon/>
              </ListItemIcon>
              <ListItemText>{this.titleCase(key)}</ListItemText>
                <ListItemIcon>
                  <>
                    { this.state.idObject && this.state.idObject['cognito:groups'].indexOf(key) !== -1 ? (
                        <PersonIcon/>
                      ) : ('')
                    }

                    { this.state.idObject && this.state.idObject['custom:adminOf'] && this.state.idObject['custom:adminOf'].indexOf(key) !== -1 ? (
                      <SupervisorAccountIcon/>
                      ) : ('')
                    }
                  </>
                </ListItemIcon>
              </ListItem>
          </Link>
        )
      })
    )
  }


  render() {
    const {
      classes,
      theme
    } = this.props;

    const drawer = (
      <div>
      <Typography variant="h3">P33Rs</Typography>
        <div className={classes.toolbar} />


        { this.state.idToken/*accessToken*/ && this.state.groupMap ? (
          <div>
            <Divider />
            <List>
              {this.generateListItems()}
            </List>
          </div>
        ) : (
          ''
        ) }




        <Divider />
        <List>
          { this.state.idToken/*accessToken*/ ? (
            <Link to="/help" onClick={this.handleDrawerToggle.bind(this, 'help')}>
            <ListItem button key="Help"><ListItemIcon><ContactSupportIcon/></ListItemIcon><ListItemText>Help</ListItemText></ListItem>
            </Link>
          ) : ('') }

          { this.state.idToken/*accessToken*/ ? (
            <Link to="/support" onClick={this.handleDrawerToggle.bind(this, 'support')}>
            <ListItem button key="Support"><ListItemIcon><ContactSupportIcon/></ListItemIcon><ListItemText>Contact Support</ListItemText></ListItem>
            </Link>
          ) : ('') }


          { this.state.idToken/*accessToken*/ ? (
            <Link to="/about" onClick={this.handleDrawerToggle.bind(this, 'about')}>
            <ListItem button key="About"><ListItemIcon><InfoIcon/></ListItemIcon><ListItemText>About</ListItemText></ListItem>
            </Link>
          ) : ('') }

          { this.state.idToken/*accessToken*/ ? (
              <Link to="/login" onClick={this.handleLog}>
              <ListItem button key="Logout"><ListItemIcon><SettingsIcon/></ListItemIcon><ListItemText>Logout</ListItemText></ListItem>
              </Link>
            ) : (
              <Link to="/login" onClick={this.handleDrawerToggle.bind(this, 'login')}>
              <ListItem button key="Login"><ListItemIcon><SettingsIcon/></ListItemIcon><ListItemText>Login</ListItemText></ListItem>
              </Link>
            ) }


        </List>

      </div>
    );

    return (
      <Router>
        <div>

          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.menuButton}
                  >
                  <MenuIcon />
                </IconButton>

<Typography>
  {/*this.state.groupMap[this.state.selectedGroupID] ? this.state.selectedGroupID : ''*/}
  {this.titleCase(this.state.crumbText)}
</Typography>

                <div className={classes.grow} />




              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="js">
                <Drawer
                  container={this.props.container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="js">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                  >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <div>
                <Switch>

                  <Route path="/group" render={(props)=><Group {...props} idObject={this.state.idObject} groupID={this.state.selectedGroupID} group={this.state.groupMap[this.state.selectedGroupID]}  api={this.state.api}/>}/>
                  <Route path="/login" render={(props)=><Login {...props} authenticate={this.authenticator.authenticate} jwt={this.state.idToken/*accessToken*/}/>}/>

                  <Route path="/about" render={(props)=>(
                    this.state.idToken/*accessToken*/ ? (
                    <About {...props} accessObject={this.state.accessObject} idObject={this.state.idObject} groupMap={this.state.groupMap}/>
                    ) : (
                      <Redirect to="/login" authenticate={this.authenticator.authenticate} jwt={this.state.idToken}/>
                    )
                  )} />
                  <Route path="/help" render={(props)=>(
                    this.state.idToken ? (
                      <Help {...props}/>
                    ) : (
                      <Redirect to="/login" authenticate={this.authenticator.authenticate} jwt={this.state.idToken/*accessToken*/}/>
                    )
                  )} />

                  <Route path="/support" component={(props)=>(
                    this.state.idToken/*accessToken*/ ? (
                      <Support {...props} jwt={this.state.idToken/*accessToken*/} api={this.state.api}/>
                    ) : (
                      <Redirect to="/login" authenticate={this.authenticator.authenticate} jwt={this.state.idToken/*accessToken*/}/>
                    )
                  )} />
                  <Route path="*" render={(props)=>(
                    this.state.idToken/*accessToken*/ ? (<Home/>) : (
                      <Redirect to="/login" authenticate={this.authenticator.authenticate} jwt={this.state.idToken/*accessToken*/}/>
                    )
                  )} />
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </Router>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};





export default withStyles(styles, {
  withTheme: true
})(ResponsiveDrawer);
