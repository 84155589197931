import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

class Login extends React.Component {
  state = {
    username: '',
    password: ''
  }

  handleLogin = event => {
    this.props.authenticate(this.state.username, this.state.password).then(
      () => {
        this.props.history.push('/about')
      }
    )
  }

  handleChange = name => event => {
    let tmp = {}
    tmp[name] = event.target.value
    this.setState(tmp)
  }

  render() {
    return (
      <div>
        <Grid container spacing={8} justify={'center'} direction={'row'}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Email Address"
              type="email"
              fullWidth
              onChange={this.handleChange('username')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              onChange={this.handleChange('password')}
            />
          </Grid>
          <Grid item xs={12}>

            <Button variant="contained" onClick={this.handleLogin} color="primary" fullWidth>
              Login
            </Button>

          </Grid>
        </Grid>
      </div>
    )
  }
}

Login.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}

export default Login
