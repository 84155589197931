import jwtDecode from 'jwt-decode'
import Amplify, { Auth } from 'aws-amplify'
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID
const POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID

Amplify.configure({
  Auth: {

    // // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: POOL_CLIENT_ID,

    // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // // OPTIONAL - customized storage object
    // storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'https://graphql-api-and-serverless-dev.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3001/',
      redirectSignOut: 'http://localhost:3001/',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
})
class Authenticator {
  constructor (authenticateCallback, cognitoAuthenticateCallback) {
    this.authenticateCallback = authenticateCallback
    this.cognitoAuthenticateCallback = cognitoAuthenticateCallback
    this.authenticate = this.authenticate.bind(this)
  }

  async authenticate (username, password) {
    try {
      const r = await Auth.signIn(username, password)
      // console.log('R', r)
      const idDecoded = jwtDecode(r.signInUserSession.idToken.jwtToken)
      const accessDecoded = jwtDecode(r.signInUserSession.accessToken.jwtToken)
      this.authenticateCallback(
        r.signInUserSession.idToken.jwtToken,
        r.signInUserSession.accessToken.jwtToken,
        idDecoded,
        accessDecoded)
    } catch (e) {
      this.authenticateCallback()
    }
  }
}
export default Authenticator
