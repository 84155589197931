import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

class Support extends React.Component {
  state = {
    administrativePOC: {
      name: '',
      phone: ''
    },
    technicalPOC: {
      name: '',
      phone: ''
    }
  }

  componentWillMount() {
    var url = 'support'
    this.props.api.get(url)
      .then((data) => {
        this.setState(data.data)
      })
  }

  render() {
    return (
      <div>
        <Typography variant = "h3" > Support < /Typography>

        <Typography variant = "h4">Administrative Contact</Typography>
        <Typography variant = "paragraph">{this.state.administrativePOC.name} {this.state.administrativePOC.phone}</Typography>

        <Typography variant = "h4">Technical Contact</Typography>
        <Typography variant = "paragraph">{this.state.technicalPOC.name} {this.state.technicalPOC.phone}</Typography>
      </div>
    )
  }
}

Support.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}

export default Support
