import React from 'react'
import PropTypes from 'prop-types'
import C3Chart from 'react-c3js'
import 'c3/c3.css'
import './my.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import StatDetailComponent from './StatDetailComponent'

class StatComponent extends React.Component {
  state = {
    valueStatMap: {},
  }
  componentWillMount = () => {
    //let url = 'stats/valueSetScores/'+this.props.valueSetID
    let url = `groups/${this.props.groupID}/value-sets/${this.props.valueSetID}/stats`
    // if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
    //   url+='/'+this.props.idObject.sub
    // }
    this.props.api.get(url).then(
      (result)=>{
        // console.log("STAT DATA", result.data)
        this.setState({valueStatMap: result.data})
      }
    )
  }
  // componentWillReceiveProps = (nextProps) => {
  //   if(nextProps.valueSetID !== this.props.valueSetID){
  //     // let url = 'stats/valueSetScores/'+nextProps.valueSetID
  //     let url = `groups/${nextProps.groupID}/value-sets/${nextProps.valueSetID}/stats`
  //     // if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
  //     //   url+='/'+this.props.idObject.sub
  //     // }
  //     this.props.api.get(url).then(
  //       (result)=>{
  //         console.log("STAT DATA2", result.data)
  //         this.setState({valueStatMap: result.data})
  //       }
  //     )
  //   }
  // }

  render () {
    let cutMap = ['Weakest','Weak','Good','Strong','Strongest']
    if(Object.keys(this.state.valueStatMap).length === 0){
      return (<CircularProgress />)
    }
    // console.log(this.state.valueStatMap)
    // const nValues = Object.keys(this.state.valueStatMap).length
    // const height = (nValues < 6 ? 50 : 100) * nValues
    // console.log("RENDER PROPS", this.props)
    // console.log("RENDER STATE", this.state)
    // let url = `groups/${this.props.groupID}/value-sets/${this.props.valueSetID}/stats`
    // // if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
    // //   url+='/'+this.props.idObject.sub
    // // }
    // if(Object.keys(this.state.valueStatMap) === 0){
    //   this.props.api.get(url).then(
    //     (result)=>{
    //       console.log("STAT DATA", result.data)
    //       this.setState({valueStatMap: result.data})
    //     }
    //   )
    // }
    const columns = [
      ['x'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return item.name/*shortName*/})),
      ['Mean (Group)'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return 1+(item.stats['*'].mean*4)})),
      // ['Min'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+(item.stats.group.min*4)})),
      // ['Max'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+(item.stats.group.max*4)})),
      // ['1StdMin'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.group.mean - (0.5*item.stats.group.std))*4)})),
      // ['1StdMax'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.group.mean + (0.5*item.stats.group.std))*4)})),
      // ['2StdMin'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.group.mean - (1*item.stats.group.std))*4)})),
      // ['2StdMax'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.group.mean + (1*item.stats.group.std))*4)})),
      // ['3StdMin'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return 1+( (item.stats.group.mean - (1.5*item.stats.group.std))*4)})),
      // ['3StdMax'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return 1+( (item.stats.group.mean + (1.5*item.stats.group.std))*4)}))
    ]
    if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
      columns.push(['Mean (You)'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return (item.stats[this.props.idObject.sub] ? 1+(item.stats[this.props.idObject.sub].mean*4) : 1+(0.5*4)) })))
      // columns.push(['Subject Min'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+(item.stats.subject.min*4)})))
      // columns.push(['Subject Max'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+(item.stats.subject.max*4)})))
      // columns.push(['Subject 1StdMin'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.subject.mean - (0.5*item.stats.subject.std))*4)})))
      // columns.push(['Subject 1StdMax'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.subject.mean + (0.5*item.stats.subject.std))*4)})))
      // columns.push(['2StdMin (You)'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.subject.mean - (1*item.stats.subject.std))*4)})))
      // columns.push(['2StdMax (You)'].concat(Object.values(this.state.valueStatMap).map((item)=>{return 1+( (item.stats.subject.mean + (1*item.stats.subject.std))*4)})))
      // columns.push(['Subject 3StdMin'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return 1+( (item.stats.subject.mean - (1.5*item.stats.subject.std))*4)})))
      // columns.push(['Subject 3StdMax'].concat(Object.values(this.state.valueStatMap[this.props.valueSetID].values).map((item)=>{return 1+( (item.stats.subject.mean + (1.5*item.stats.subject.std))*4)})))
    }

    // let cutMap = ['Weakest','Weak','Good','Strong','Strongest'] //TODO: Move to ValueSet
    let chartConfig = {
      // size: {
      //   height: height
      // },
      data: {
        x: 'x',
        columns: columns,
        types: {
          'Mean (You)': 'bar', //'bar',
          'Mean (Group)': 'bar',
          // 'Subject Min': 'scatter',
          // 'Subject Max': 'scatter',
          // 'Min': 'scatter',
          // 'Max': 'scatter',
          // 'Subject 1StdMin': 'scatter',
          // 'Subject 1StdMax': 'scatter',
          '2StdMin (You)': 'bar',
          '2StdMax (You)': 'bar',
          // 'Subject 3StdMin': 'scatter',
          // 'Subject 3StdMax': 'scatter',
          // '1StdMin': 'scatter',
          // '1StdMax': 'scatter',
          '2StdMin': 'bar',
          '2StdMax': 'bar',
          // '3StdMin': 'scatter',
          // '3StdMax': 'scatter'
        },
        colors: {
          'Mean (You)': 'rgba(63,81,181,1.0)',
          'Mean (Group)': 'rgba(63,81,181,0.5)',
          '2StdMin (You)': 'rgba(255,0,0,0.2)',
          '2StdMax (You)': 'rgba(255,0,0,0.2)',
          '2StdMin': 'rgba(0,255,0,0.2)',
          '2StdMax': 'rgba(0,255,0,0.2)'
        },
      },
      axis: {
        rotated: true,
        x: {
          type: 'category', // this needed to load string x value
          // tick: {
          //   rotate: -20,
          //   multiline: false,
          //   height: 35
          // }
        },
        y: {
          min: 1,
          max: 5,
          //type: 'category',
          tick: {
            //count: cutMap.length,
            values: [1,2,3,4,5],
            format: (d)=>{
              switch(d){
                case(1): return cutMap[0]
                case(2): return cutMap[1]
                case(3): return cutMap[2]
                case(4): return cutMap[3]
                case(5): return cutMap[4]
                default: return d.toFixed(4)
              }
            //  return d
            //   console.log("FORMAT", d)
            //   if(Math.floor(d) === 1) return cutMap[0]
            //   if(Math.floor(d) === 2) return cutMap[1]
            //   if(Math.floor(d) === 3) return cutMap[2]
            //   if(Math.floor(d) === 4) return cutMap[3]
            //   if(Math.floor(d) === 5) return cutMap[4]
            //   return d
            }
            //count: 5, //cutMaps[0].length,
            // format: (d) => {
            //   return (cutMaps[0][d] || undefined)
            // }
          }
        }
      },
      grid: {
        x: {show: true},
        y: {show: true}
      },
      size: {
        //height: Object.values(this.state.valueStatMap).length * 50,
        height: columns[0].length * (300/4),
        maxHeight: 1400
      }
    }
//    <C3Chart {...chartConfig} style={{"height": "1200px"}}/>
//<C3Chart {...chartConfig} style={{'max-height': chartConfig.size.height, height: chartConfig.size.height}}/>
    return (
      <>
      <C3Chart {...chartConfig}/>
      {
        Object.values(this.state.valueStatMap[this.props.valueSetID].values).sort((a,b)=>{
          if(a.ordinal < b.ordinal) return -1
          if(a.ordinal > b.ordinal) return 1
          return 0
        }).map((valueStat)=>{
          return <StatDetailComponent key={valueStat.name} valueStat={valueStat} idObject={this.props.idObject} cutMap={cutMap}/>
        })
      }
      </>
    )
  }
}

StatComponent.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}
export default StatComponent
