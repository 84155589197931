import React from 'react'
import PropTypes from 'prop-types'
import C3Chart from 'react-c3js'
import 'c3/c3.css'

class HistoryComponent extends React.Component {
  state = {
    valueStatMap: {}
  }
  componentWillMount = () => {
    let url = 'stats/valueSetScoresHistory/'+this.props.valueSetID
    if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
      url+='/'+this.props.idObject.sub
    }
    this.props.api.get(url).then(
      (result)=>{
        this.setState({valueStatMap: result.data})
      }
    )
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.valueSetID !== this.props.valueSetID){
      let url = 'stats/valueSetScoresHistory/'+nextProps.valueSetID
      if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
        url+='/'+this.props.idObject.sub
      }
      this.props.api.get(url).then(
        (result)=>{
          this.setState({valueStatMap: result.data})
        }
      )
    }
  }

  render () {
    const xArr = ['x']
    const groupArrs = {}
    const subjectArrs = {}
    for(var key of Object.keys(this.state.valueStatMap)){
      const gkey = key
      const skey = key+'(You)'
      if(!groupArrs[gkey]) groupArrs[gkey] = [gkey]
      if(!subjectArrs[skey]) subjectArrs[skey] = [skey]

      groupArrs[gkey] = groupArrs[gkey].concat(Object.keys(this.state.valueStatMap[key].windows).map((entryKey)=>{
        const entry = this.state.valueStatMap[key].windows[entryKey]
        //if(groupArrs['x'].indexOf(entryKey) === -1) groupArrs['x'].push(entryKey);
        if(xArr.indexOf(entryKey) === -1) xArr.push(entryKey);
        return 1+(entry.group.mean*4)
      }))

      if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
        subjectArrs[skey] = subjectArrs[skey].concat(Object.keys(this.state.valueStatMap[key].windows).map((entryKey)=>{
          const entry = this.state.valueStatMap[key].windows[entryKey]
          //if(subjectArrs['x'].indexOf(entryKey) === -1) subjectArrs['x'].push(entryKey);
          if(xArr.indexOf(entryKey) === -1) xArr.push(entryKey);
          return 1+(entry.subject.mean*4)
        }))
      }
    }
    const columns = [
      xArr
    ]
    Object.values(groupArrs).forEach((groupArr)=>{
      columns.push(groupArr)
    })

    if(this.props.idObject['cognito:groups'].indexOf(this.props.groupID)!==-1){
      Object.values(subjectArrs).forEach((subjectArr)=>{
        columns.push(subjectArr)
      })
    }

    let cutMap = ['Weakest','Weak','Good','Strong','Strongest'] //TODO: Move to ValueSet
    let chartConfig = {
      data: {
        x: 'x',
        columns: columns,
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m' //'%Y-%m-%d'
          }
        },
        y: {
          min: 1,
          max: 5,
          padding: {top: 0, bottom: 0},
          tick: {
            //count: cutMap.length,
            values: [1,2,3,4,5],
            format: (d)=>{
              switch(d){
                case(1): return cutMap[0]
                case(2): return cutMap[1]
                case(3): return cutMap[2]
                case(4): return cutMap[3]
                case(5): return cutMap[4]
                default: return d.toFixed(4)
              }
            //  return d
            //   console.log("FORMAT", d)
            //   if(Math.floor(d) === 1) return cutMap[0]
            //   if(Math.floor(d) === 2) return cutMap[1]
            //   if(Math.floor(d) === 3) return cutMap[2]
            //   if(Math.floor(d) === 4) return cutMap[3]
            //   if(Math.floor(d) === 5) return cutMap[4]
            //   return d
            }
          }
        },
      },
      grid: {
        x: {
            show: true
        },
        y: {
            show: true
        }
      }
    }
    return (
      <C3Chart {...chartConfig}/>
    )
  }
}

HistoryComponent.propTypes = {
  // classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}
export default HistoryComponent
