import React from 'react'
import PropTypes from 'prop-types'
import C3Chart from 'react-c3js'
import 'c3/c3.css'
import './my.css'

class StatDetailComponent extends React.Component {
  render () {
    // console.log('THOR', this.props.idObject)
    // console.log('BERT', this.props.valueStat)

    const personStat = this.props.valueStat.stats[this.props.idObject.sub]
    const groupStat = this.props.valueStat.stats['*']

    // if (this.props.valueStat) console.log('THOR', this.props.valueStat.stats[this.props.idObject.sub])
    let message
    if (personStat) {
      if (Math.abs(personStat.mean - groupStat.mean) < 0.01) {
        message = <span>You're tracking with the group.</span>
      }
      // if (personStat.mean < groupStat.mean) {
      if (groupStat.mean - personStat.mean > 0.01) {
        message = <span>You're lagging the group by {Math.round(groupStat.mean * 100 - personStat.mean * 100)}%.</span>
      }
      if (personStat.mean - groupStat.mean > 0.01) {
        message = <span>You're leading the group by {Math.round(personStat.mean * 100 - groupStat.mean * 100)}%.</span>
      }
      // console.log('minIndex', Math.floor(personStat.min * this.props.cutMap.length))
      // console.log('maxIndex', Math.floor(personStat.max * this.props.cutMap.length))
    }
    // console.log('CUTMAP', this.props.cutMap)

    return (
      <>
        <p><strong>{this.props.valueStat.name}</strong> {this.props.valueStat.description}
          {personStat
            ? <>
              <span>You've been reviewed {personStat.n} times by {personStat.observers.length} peers, with scores ranging from {this.props.cutMap[Math.floor(personStat.min * this.props.cutMap.length)]} to {this.props.cutMap[Math.floor(personStat.max * this.props.cutMap.length)]}.</span>
              { message }
            </>
            : <span> You haven't received any reviews.</span>
          }
        </p>
      </>
    )
  }
}

StatDetailComponent.propTypes = {
  // classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired
}
export default StatDetailComponent
