import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'

import { withStyles } from '@material-ui/core/styles';
import { Slider } from 'material-ui-slider'

function shuffle (array) {
  var i = 0

  var j = 0

  var temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return (array)
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  // typography: {
  //   padding: theme.spacing.unit * 2,
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // },
})

class RateComponent extends React.Component {
  queue = {}
  state = {
    valueMap: {},
    measureMap: {},
    memberMap: {},
    selectedMemberKey: undefined,
    savedConfirmationVisible: false
  }
  queueObservation = (measureID, direction, value) => {
    let scaledValue = ((direction === '+' ? value : 100-value) /100)
    this.queue[measureID/*+this.state.selectedMemberKey*/] = {
      MeasureId: measureID,
      SubjectId: this.state.selectedMemberKey,
      value: scaledValue
    }
  }
  postObservations = (measureID, direction, min, max, value) => {
    if(Object.keys(this.queue).length > 0){
      this.setState(state => ({
        savedConfirmationVisible: true
      }))
      this.props.api.post('observations', Object.values(this.queue)).then(
        (result)=>{
          this.queue = []
        },
        (error)=>{
          console.error(error)
        }
      )
    }
  }
  clearSelectedMemberKey = () => {
    this.setState({selectedMemberKey: undefined})
  }
  componentWillMount = () => {
    // let url = 'values/'+this.props.valueSetID
    let url = `groups/${this.props.groupID}/value-sets/${this.props.valueSetID}/values`
    this.props.api.get(url).then(
      (result)=>{
        let promises = Object.keys(result.data).map((valueID)=>{
          let url = `groups/${this.props.groupID}/value-sets/${this.props.valueSetID}/values/${result.data[valueID].id}/measures`
          //console.log("THOR", url)
          return this.props.api.get(url)
        })
        Promise.all(promises).then(
          (r)=>{
            let tmp = {}
            r.forEach((e)=>{
              //this.setState({measureMap: Object.assign({}, this.state.measureMap, e.data)})
              e.data.forEach((item)=>{
                tmp[item.id] = item
              })
            })
            this.setState({measureMap: Object.assign({}, this.state.measureMap, tmp)})
          },
          (error)=>{console.error(error)}
        )
      }
    )


  }
  pickSubject = (event, value, reason) => {
    this.setState({selectedMemberKey: value ? value.key : undefined})
  }
  handleClose = () => {
    this.setState(state => ({
      savedConfirmationVisible: false
    }))
  }
  render () {
    if(Object.keys(this.state.measureMap).length === 0){
      return (<CircularProgress />)
    }
    // let measures = Object.values(this.props.valueSetScore).reduce((acc, value) => {
    //   Object.values(value.measures).forEach((measure) => {
    //     acc.push(measure)
    //   })
    //   return (acc)
    // }, [])
    // shuffle(measures)
    // return (
    //   <div>
    //     {
    //       measures
    //         .map((measure) => {
    //           let cuts = Object.keys(this.props.valueSetScore[measure.parent].value.cutMap).sort().map((index)=>{return(this.props.valueSetScore[measure.parent].value.cutMap[index])})
    //           return (
    //
    //               <Grid container spacing={0}>
    //
    //                 <Grid item xs={12}>
    //                   <Typography variant="body1" align='center'>{measure.text}</Typography>
    //                 </Grid>
    //
    //                 <Grid container spacing={0} alignItems='center'>
    //                   <Grid item xs={2}>
    //                     <Typography align='left'>{cuts[0]}</Typography>
    //                   </Grid>
    //                   <Grid item xs>
    //                     <Slider
    //                       min={0/*this.props.valueSetScore[measure.parent].value.min*/}
    //                       max={100/*this.props.valueSetScore[measure.parent].value.max*/}
    //                       defaultValue={ // default to group mean
    //                           (this.props.valueSetScore[measure.parent].group.mean /
    //                           (this.props.valueSetScore[measure.parent].value.min + (this.props.valueSetScore[measure.parent].value.max - this.props.valueSetScore[measure.parent].value.min)))
    //                           * 100}
    //                       onChangeComplete={this.postObservation.bind(this,  measure._id, measure.direction, this.props.valueSetScore[measure.parent].value.min, this.props.valueSetScore[measure.parent].value.max)} >
    //                     </Slider>
    //                   </Grid>
    //                   <Grid item xs={2}>
    //                     <Typography align='right'>{cuts[cuts.length-1]}</Typography>
    //                   </Grid>
    //                 </Grid>
    //
    //               </Grid>
    //
    //           )
    //         })
    //     }
    //   </div>
    const measures = this.state.measureMap ? shuffle(Object.values(this.state.measureMap)) : []

    // <Button variant="contained"
    //         color="secondary"
    //         disabled={this.state.selectedMemberKey ? false : true}
    //         onChange={this.clearSelectedMemberKey} >
    //         Post
    // </Button>
    return(
      <div>
      {
        <Autocomplete
            id="groupMemberAutoComplete"
            onChange={this.pickSubject}

            options={
              this.props.memberMap ?
                Object.keys(this.props.memberMap).map(
                  (key)=>{
                    return {
                      key:key,
                      firstName: this.props.memberMap[key].firstName,
                      lastName: this.props.memberMap[key].lastName}}).sort((a,b)=>{if(a.lastName+a.firstName < b.lastName+b.firstName) return -1; if(a.lastname+a.firstName > b.lastName+b.firstName) return 1; return 0}) : []}
            getOptionLabel={option => option.firstName + ' ' + option.lastName}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label="Choose a person" variant="outlined" />}
          />


      }
{
  <Button variant="contained" onClick={this.postObservations}>Save</Button>
}
        {
          measures
            .map((measure) => {
              //let cuts = Object.keys(this.props.valueSetScore[measure.parent].value.cutMap).sort().map((index)=>{return(this.props.valueSetScore[measure.parent].value.cutMap[index])})
              let  cuts = ['never','b','c','d','always']
              return (

                  <Grid container spacing={0} key={measure.id}>

                    <Grid item xs={12}>
                      <Typography variant="body1" align='center'>{measure.text}</Typography>
                    </Grid>

                    <Grid container spacing={0} alignItems='center'>
                      <Grid item xs={2}>
                        <Typography align='left'>{cuts[0]}</Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          disabled={this.state.selectedMemberKey ? false : true}
                          min={0/*this.props.valueSetScore[measure.parent].value.min*/}
                          max={100/*this.props.valueSetScore[measure.parent].value.max*/}
                          defaultValue = {50}
                          onChangeComplete={this.queueObservation.bind(this,  measure.id, measure.direction)} >
                        </Slider>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography align='right'>{cuts[cuts.length-1]}</Typography>
                      </Grid>
                    </Grid>

                  </Grid>

              )
            })
        }


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.savedConfirmationVisible}
          autoHideDuration={5000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Your observations were recorded</span>}
          />


      </div>
    )
    // <ul>
    //   {Object.keys(this.props.memberMap).sort((a,b)=>{
    //     if(this.props.memberMap[a].lastName+this.props.memberMap[a].firstName < this.props.memberMap[b].lastName+this.props.memberMap[b].firstName) return -1;
    //     if(this.props.memberMap[a].lastName+this.props.memberMap[a].firstName > this.props.memberMap[b].lastName+this.props.memberMap[b].firstName) return 1;
    //     return 0}).map((key)=>{
    //       return(
    //         this.props.memberMap[key].firstName +' ' +this.props.memberMap[key].lastName
    //       )
    //     }
    //   ).join(', ')}
    // </ul>

    // return 0}).map((key)=>{
    //   return(
    //     <li key={key}>
    //     {this.props.memberMap[key].firstName} {this.props.memberMap[key].lastName}
    //     </li>
    //   )
    // }

    // defaultValue={ // default to group mean
    //     (this.props.valueMap[measure._id].group.mean /
    //     (this.props.valueMap[measure._id].value.min + (this.props.valueMap[measure._id].value.max - this.props.valueSetScore[measure.parent].value.min)))
    //     * 100}

  }
}

RateComponent.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}
export default withStyles(styles)(RateComponent)
