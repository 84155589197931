import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

//import 'c3/c3.css';

import StatComponent from '../StatComponent'
import HistoryComponent from '../HistoryComponent'
import RateComponent from '../RateComponent'
import CORComponent from '../CORComponent'

class ValueSet extends React.Component {

  state = {
    tabNumber: 0
  }

  handleChange = (event, tabNumber) => {
    this.setState({
      tabNumber
    })
  }

  render() {
    const beginDate = new Date(this.props.valueSet.beginDate)
    const endDate = new Date(this.props.valueSet.endDate)
    beginDate.setDate(beginDate.getDate()+1)
    endDate.setDate(endDate.getDate()+1)
    return (
        <Card>
          <CardHeader
            title={this.props.valueSet.name + " (" + beginDate.toDateString() + " through " + endDate.toDateString() + ")"}
          />
          <CardContent>
            <Tabs value={this.state.tabNumber} onChange={this.handleChange}>
              <Tab label="Current" />
              <Tab label="History" />
              <Tab label="Rate" />
              <Tab label="COR" />
            </Tabs>


            {this.state.tabNumber === 0 &&
              <StatComponent key={'sc'+this.props.valueSetID} api={this.props.api} groupID={this.props.groupID} idObject={this.props.idObject} valueSetID={this.props.valueSetID} valueStatMap={this.state.valueStatMap}/>
            }

            {/*this.state.tabNumber === 1 &&
              <HistoryComponent key={'hs'+this.props.valueSetID} api={this.props.api} groupID={this.props.groupID} memberMap={this.props.memberMap} idObject={this.props.idObject} valueSetID={this.props.valueSetID} valueSet={this.props.valueSet}/>
            */}

            {this.state.tabNumber === 2 &&
              <RateComponent key={'rc'+this.props.valueSetID} api={this.props.api} groupID={this.props.groupID} memberMap={this.props.memberMap} idObject={this.props.idObject} valueSetID={this.props.valueSetID} valueSet={this.props.valueSet}/>
            }

            {this.state.tabNumber === 3 &&
              <CORComponent key={'cc'+this.props.valueSetID} api={this.props.api} groupID={this.props.groupID} memberMap={this.props.memberMap} idObject={this.props.idObject} valueSetID={this.props.valueSetID} valueStatMap={this.state.valueStatMap}/>
            }

          </CardContent>
        </Card>
    )
  }
  //{...this.props}
}
ValueSet.propTypes = {
  // classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired
}
export default ValueSet
