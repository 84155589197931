import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

class Home extends React.Component {
  render () {
    return (
      <div>
        <Typography variant = "h1" > Home < /Typography>
      </div>
    )
  }
}

Home.propTypes = {
  // classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired
}
export default Home
